import React from 'react';
import Pic1 from "../../assets/img/Adds/1.jpg"
import Pic2 from "../../assets/img/Adds/2.jpg"
import Pic3 from "../../assets/img/Adds/3.jpg"
const Carousal = () => {
  return (
    <div id="carouselExampleIndicators" class="carousel slide mt-2" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
     
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src={Pic1} class="d-block w-100" alt="..."></img>
      </div>
      <div class="carousel-item">
      <img src={Pic2} class="d-block w-100" alt="..."></img>
      </div>
      <div class="carousel-item">
      <img src={Pic3} class="d-block w-100" alt="..."></img>
      </div>
      <div class="carousel-item">
      <img src={Pic2} class="d-block w-100" alt="..."></img>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
 
  );
};

export default Carousal;