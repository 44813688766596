import React from "react";
import './App.css';
import Logo from "./component/assets/img/Logos/Nandi.png";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { height } from "@fortawesome/free-solid-svg-icons/fa0";
import Home from "./component/Home";



function App() {
  return (
   
  <>
   <Navbar expand="sm" className="bg-dark mb-3 " style={{ height: '75px' }} >
  <Container fluid >
  <div className="logoicon">
      <img src={Logo} style={{ width: '5rem' }} className="mt-2" ></img>
      </div>
    <Navbar.Brand href="#" className="text-white">హలో <span class="text-warning ">నంద్యాల</span>
    <p class="caption text-info ml-5 ">డిజిటల్ టెలిఫోన్ డైరెక్టరీ</p>
    </Navbar.Brand>
    
    <Navbar.Toggle aria-controls="offcanvasNavbar-expand-sm " className="text-white" />
    <Navbar.Offcanvas
      id="offcanvasNavbar-expand-sm"
      aria-labelledby="offcanvasNavbarLabel-expand-sm"
      placement="end"
      className="bg-dark"
    >
      <Offcanvas.Header closeButton className="text-white">
        <Offcanvas.Title id="offcanvasNavbarLabel-expand-sm" className="text-white">హలో <span class="text-warning ">నంద్యాల</span>
        <p class="caption text-info ml-5 ">డిజిటల్ టెలిఫోన్ డైరెక్టరీ</p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav className="justify-content-end flex-grow-1 pe-3">
        <ul class="navbar-nav ml-auto">
                <li class="nav-item px-3 ">
                    <a href="/" class="nav-link text-white text-decoration-none"><i class="fa fa-home text-primary fa-1x"></i>
                        <span class="text-white ">  &nbsp;&nbsp;హోమ్ </span></a>
                </li>
                <li class="nav-item px-3">
                    <a href="/" class="nav-link text-white"><i class="fa-solid fa-photo-film text-warning"></i>

                        <span class="text-white ">&nbsp;&nbsp; ఫోటోలు </span></a>
                </li>
                <li class="nav-item px-3">
                    <a href="/" class="nav-link text-white"><i class="fa-solid fa-clapperboard text-danger"></i>
                        <span class="text-white ">&nbsp;&nbsp; వీడియోలు</span></a></li>
                <li class="nav-item px-3">
                    <a href="/" class="nav-link text-white"><i class="fa-solid fa-user text-info"></i>
                        <span class="text-white ">&nbsp;&nbsp; మన గురించి</span></a></li>
                               
                <li class="nav-item px-3 ">
                    <a href="/" class="nav-link text-white text-decoration-none"><i class="fa-solid fa-phone-volume " ></i>
                        <span class="text-white">&nbsp;&nbsp;  సంప్రదించండి </span></a>
                </li>
                
                
               
            </ul>
        </Nav>
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  </Container>
</Navbar>
 <Home/>
 

</>
    
  );
};

export default App;
