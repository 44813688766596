
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@fortawesome/react-fontawesome';
import './index.css';
import './App.css';
import App from "./App"
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-icons/font/bootstrap-icons.css";





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
     <App />
  </>
     

    
);

