
import React from "react";



function displayWelcomeMessage() {
   
    let teluguName = prompt("మీ పేరు టైపు చేయండి.");
    document.querySelector(".welcome").innerHTML = "స్వాగతం "  + teluguName + " గారు ";
      
}
window.onload = displayWelcomeMessage;




const Welcome = () => {
    return (
        <>
        
    <h1 class="welcome"></h1>


<div class="massage">
  <h1 class="mt-3 text-center text-black ">ధన్యవాదములు</h1>
  <h4 class="mt-3 text-center text-white bg-dark mt-2 fw-bold scrolling">
      <marquee> సంప్రదించవలసినవారు :- <span    >&nbsp; హలో నంద్యాల &nbsp;&nbsp;</span><span>9396667728</span></marquee></h4>

</div>

        </>
    );
};

export default Welcome;
