import React from "react";
import Welcome from "./pages/maipage/Welcome";
import Carousal from "./pages/maipage/Carousel";



const Home = () => {
    return (
        <>
       
        <Welcome/>
        <Carousal/>
        </>
    );
};

export default Home;